import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputBase, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ResetPasswordDialog = ({ openResetPasswordDialog, setOpenResetPasswordDialog, setOpenLoginSignup }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [newPassword, setNewPassword] = useState('');

  const onSubmit = () => {
    if (newPassword.trim().length < 6) {
      enqueueSnackbar(translate('password_atleast_6_characters'), snackbarOptionsError);
      return;
    }

    conObj
      .post(`password_reset/${sessionStorage.getItem('resetToken')}.json`, { 'user-new_password': newPassword.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            sessionStorage.removeItem('resetToken');
            enqueueSnackbar(translate('password_reset_success'), snackbarOptionsSuccess);
            setOpenLoginSignup({ open: true, isLogin: true });
          }
        }
      })
      .catch((err) => {
        sessionStorage.removeItem('resetToken');
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenResetPasswordDialog(false);
      });
  };

  return (
    <Modal open={openResetPasswordDialog} onClose={() => setOpenResetPasswordDialog(false)} sx={{ overflow: 'hidden' }}>
      <Box
        sx={{
          backgroundColor: '#161f2c',
          borderRadius: '20px',
          height: 'auto',
          width: '96vw',
          maxWidth: '500px',
          overflow: 'auto',
          p: '50px 24px 24px',
          m: 'auto',
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          position: 'relative',
          marginTop: '50px',
        }}
        className="modal-container container-no-scrollbar"
      >
        <Iconify
          icon={'ep:close'}
          width={24}
          height={24}
          sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16, color: 'rgb(147, 172, 211)' }}
          onClick={() => setOpenResetPasswordDialog(false)}
        />

        <Typography sx={{ fontWeight: 600, color: 'white', fontSize: '18px', mb: 2 }}>
          {translate('reset_password')}
        </Typography>
        <Box>
          <Box sx={{ mb: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
            >
              <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#55657e' }} />
              <InputBase
                type="password"
                name="newPassword"
                value={newPassword}
                placeholder={translate('new_password')}
                //   InputLabelProps={{
                //     style: { color: '#999999' },
                //  }}
                sx={{ ml: 1, fontSize: '14px', fontWeight: 600, fontFamily: 'sans-serif', flexGrow: 1, color: 'white' }}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Stack>
          </Box>

          <Button sx={{ width: '100%' }} onClick={() => onSubmit()}>
            {translate('confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ResetPasswordDialog.propTypes = {
  openResetPasswordDialog: PropTypes.bool,
  setOpenResetPasswordDialog: PropTypes.func,
  setOpenLoginSignup: PropTypes.func,
};

export default ResetPasswordDialog;
