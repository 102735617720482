import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, Grid, Stack, Toolbar, Typography } from '@mui/material';
// import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import LoginSignupDialog from './visitor/LoginSignupDialog';
import ForgotPasswordDialog from './forgotpassword/ForgotPasswordDialog';
import ResetPasswordDialog from './forgotpassword/ResetPasswordDialog';
import { useDispatch, useSelector } from '../../../redux/store';
import { openLoginSignup } from '../../../redux/slices/authPages';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import Image from '../../../components/Image';
import NotificationPopover from './NotificationPopover';
import AccountPopover from './AccountPopover';
import useLocales from '../../../hooks/useLocales';
import { fNumber, fNumberDecimal } from '../../../utils/formatNumber';
import palette from '../../../theme/palette';
import { ThemeButton } from '../../GlobalStyles';
import useAuth from '../../../hooks/useAuth';
import LanguagePopover from './LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  // boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  backgroundColor: palette.navBarVertical.bg,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { isAuthenticated } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { memberBalance } = useSelector((x) => x.lookup);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('referralCode') && !localStorage.getItem('accessToken')) {
      setTimeout(() => {
        dispatch(openLoginSignup({ open: true, isLogin: false }));
      }, 1000);
    }
    // Check if got resetpassword token
    if (sessionStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      // getProfile();
    } else {
      console.log('loggedoff');
    }
  }, [localStorage.getItem('accessToken')]);

  // useEffect(() => {
  //   if (isOpenLoginSignupDialog.open)
  //     setTimeout(() => {
  //       loadCaptchaEnginge(4);
  //     }, 1000);
  // }, [isOpenLoginSignupDialog]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        {/* {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />} */}

        {/* {!isDesktop && ( */}
        <Stack direction="row" alignItems={'center'}>
          <Logo />
        </Stack>
        {/* )} */}

        {/* {isDesktop && (
          <Stack direction="row" alignItems={'center'}>
            <Box
              onClick={() => onToggleCollapse()}
              sx={{
                background: palette.header.menuBg,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '4px',
                p: '5px',
                mr: 2,
                cursor: 'pointer',
              }}
            >
              <Iconify icon={'ion:menu'} color={palette.header.menu} width="25px" height="25px" />
            </Box>
            <Image
              className="header-logo"
              onClick={() => navigate('/home')}
              src={require('../../../assets/brand-logo.png')}
              alt="App Logo"
              sx={{
                height: '50px',
                width: 'auto',
                ml: 1,
                cursor: 'pointer',
                '& img': {
                  width: 'auto !important',
                },
              }}
            />
          </Stack>
        )} */}

        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={1}>
          {!localStorage.getItem('accessToken') ? (
            <>
              <Button
                variant="text"
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
                sx={{ color: palette.dark.common.white, border: '1px solid #fff' }}
              >
                {translate('login')}
              </Button>
              <ThemeButton onClick={() => dispatch(openLoginSignup({ open: true, isLogin: false }))}>
                {translate('sign_up')}
              </ThemeButton>
            </>
          ) : (
            <Stack direction={'row'} spacing={0.3} sx={{ alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  borderRadius: '6px',
                  background: palette.header.balance_button.bg,
                }}
              >
                <Stack
                  direction="row"
                  spacing={'5px'}
                  sx={{
                    p: '5px',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  {/* <Image src={require('../../../assets/home/bitcoin.png')} sx={{ width: '20px', height: '20px' }} /> */}
                  <Iconify
                    icon="mdi:bangladeshi-taka"
                    sx={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      border: '1.5px solid #fff',
                      padding: '2px',
                    }}
                  />
                  <Typography
                    onClick={() =>
                      // dispatch(
                      //   openTransactionsDialog({
                      //     open: true,
                      //     isDeposit: true,
                      //   })
                      // )
                      navigate('/user/wallet')
                    }
                    sx={{
                      // fontSize: '0.25rem',
                      cursor: 'pointer',
                      color: palette.header.balance_button.color,
                    }}
                  >
                    {`${memberBalance?.displayBalance ? fNumber(memberBalance?.displayBalance) : '0'}`}
                  </Typography>
                </Stack>
                <Stack
                  onClick={() =>
                    // dispatch(
                    //   openTransactionsDialog({
                    //     open: true,
                    //     isDeposit: true,
                    //   })
                    // )
                    navigate('/user/wallet')
                  }
                  direction={'row'}
                  spacing={'2px'}
                  alignItems="center"
                  sx={{
                    borderRadius: '6px',
                    p: '5px',
                    background: palette.header.deposit_btn.bg,
                    cursor: 'pointer',
                    boxShadow: '0px 0px 5px 0px #FFD633',
                    border: '1px solid #FFF1B9',
                  }}
                >
                  <Iconify icon={'game-icons:wallet'} width={20} height={20} sx={{ color: 'white' }} />
                  <Typography
                    sx={{
                      color: palette.header.deposit_btn.color,
                      display: { sm: 'unset', display: 'none' },
                      // fontSize: '0.3rem',
                    }}
                  >
                    {translate('deposit')}
                  </Typography>
                </Stack>
              </Box>
              {/* <button
              style={{
                backgroundColor: '#ff3141',
                border: 'none',
                padding: '3px 10px',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
              onClick={() =>
                dispatch(
                  openTransactionsDialog({
                    open: true,
                    isDeposit: true,
                  })
                )
              }
            >
              <Iconify icon={'fluent:wallet-24-filled'} width={20} height={20} sx={{ color: 'white' }} />
            </button> */}

              <NotificationPopover />

              <AccountPopover />
            </Stack>
          )}
          {isDesktop && <LanguagePopover />}
        </Stack>

        {isOpenLoginSignupDialog.open && (
          <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
        )}

        {openForgotPasswordDialog && (
          <ForgotPasswordDialog
            openForgotPasswordDialog={openForgotPasswordDialog}
            setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
          />
        )}

        {openResetPasswordDialog && (
          <ResetPasswordDialog
            openResetPasswordDialog={openResetPasswordDialog}
            setOpenResetPasswordDialog={setOpenResetPasswordDialog}
            setOpenLoginSignup={(params) => dispatch(openLoginSignup(params))}
          />
        )}
      </Toolbar>
    </RootStyle>
  );
}
